import React from "react";
import PropTypes from "prop-types";
import { Link, graphql, StaticQuery } from "gatsby";
import core from "../img/exlib/exlib-core.jpg";
import cardio from "../img/exlib/exlib-cardio.jpg";
import lowerBody from "../img/exlib/exlib-lower-body.jpg";
import upperBody from "../img/exlib/exlib-upper-body.jpg";

class WorkoutVideoTags extends React.Component {
  render() {
    const tags = this.props.data.allMarkdownRemark.group;
    const buttons = tags
      .filter((x) => x.fieldValue !== "Workouts")
      .map((post) => {
        let image = null;
        const alt = post.fieldValue;
        if (post.fieldValue.toLowerCase() === "cardio") {
          image = cardio;
        } else if (post.fieldValue.toLowerCase() === "upper body") {
          image = upperBody;
        } else if (post.fieldValue.toLowerCase() === "lower body") {
          image = lowerBody;
        } else if (post.fieldValue.toLowerCase() === "core") {
          image = core;
        }

        return (
          <div key={post.fieldValue} className="column is-3">
            <div className="card">
              <div className="card-image">
                <figure className="image is-square">
                  <img src={image} alt={alt} />
                </figure>
              </div>
              <div className="card-content">
                <div className="media">
                  <div className="media-content">
                    <p className="title is-4">{post.fieldValue}</p>
                    <p className="subtitle is-6">
                      {post.totalCount} video
                      {post.totalCount > 1 && "s"}
                    </p>
                  </div>
                </div>

                <footer
                  className="card-footer"
                  style={{ paddingTop: "1.5rem" }}
                >
                  <p className="card-footer-item ">
                    <Link
                      to={`/training-videos/tag/${post.fieldValue}`}
                      className="dark-link"
                    >
                      Browse {post.fieldValue}
                    </Link>
                  </p>
                </footer>
              </div>
            </div>
          </div>
        );
      });

    return (
      <div style={{ marginBottom: "200px" }}>
        <div className="columns is-multiline has-text-centered">{buttons}</div>
      </div>
    );
  }
}

WorkoutVideoTags.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      group: PropTypes.array,
    }),
  }),
};

const WorkoutVideoTagsFinal = () => (
  <StaticQuery
    query={graphql`
      query WorkoutTagsQuery {
        allMarkdownRemark(
          sort: {
            order: ASC
            fields: [frontmatter___title, frontmatter___tags]
          }
          filter: {
            frontmatter: {
              templateKey: { eq: "training-video-post" }
              tags: { in: ["Upper Body", "Lower Body", "Core", "Cardio"] }
            }
          }
        ) {
          group(field: frontmatter___tags) {
            fieldValue
            totalCount
          }
        }
      }
    `}
    render={(data) => <WorkoutVideoTags data={data} />}
  />
);

export default WorkoutVideoTagsFinal;
