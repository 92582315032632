import React from "react";

import Layout from "../../../components/Layout";
import * as ROUTES from "../../../constants/routes";
import { compose } from "recompose";
import { Link } from "gatsby";
import {
  withAuthorization,
  withActiveSubscription,
} from "../../../components/Session";
import TrainingVideosListing from "../../../components/TrainingVideosListing";
import WorkoutVideoTags from "../../../components/WorkoutVideoTags";
import PageHeader from "../../../components/PageHeader";

const TrainingVideosIndexPageBase = ({ pageContext }) => {
  let title = null;
  if (pageContext.item) {
    title = {
      breadcrumb: (
        <Link to={ROUTES.TRAINING_VIDEOS}>Workout Video Library</Link>
      ),
      title: pageContext.item.fieldValue,
    };
  } else {
    title = {
      breadcrumb: (
        <Link to={ROUTES.TRAINING_VIDEOS}>Workout Video Library</Link>
      ),
      title: "Workouts",
    };
  }

  return (
    <div className="exercise-library-page">
      <PageHeader {...title} />
      <div style={{ padding: "2rem" }}>
        {pageContext.item === undefined && (
          <>
            <p>Select a category...</p>
            <WorkoutVideoTags />
          </>
        )}
        {pageContext.item && (
          <TrainingVideosListing pageContext={pageContext} />
        )}
      </div>
    </div>
  );
};

const condition = (authUser) => !!authUser;

const TrainingVideosIndexPage = compose(
  withAuthorization(condition),
  withActiveSubscription
)(TrainingVideosIndexPageBase);

const TrainingVideosIndexPageFinal = ({ pageContext }) => (
  <Layout>
    <TrainingVideosIndexPage pageContext={pageContext} />
  </Layout>
);

export default TrainingVideosIndexPageFinal;
